/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from 'react-helmet'

import Header from "./header"
import Footer from "./footer"
import Modals from "./modals"
// import "./layout.css"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet 
          bodyAttributes={{
              class: 'template-homepage'
          }}
      >
      </Helmet>
      <div className="pagewrap">
        <Header location={location} siteTitle={data.site.siteMetadata.title}/>
        <div id="content" role="main" className="page-layout">
          <main>{children}</main>
        </div>
        <Footer />
        <Modals />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object
}

export default Layout