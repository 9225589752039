import { Link } from "gatsby"
import React, { Component } from "react"
import Mailchimp from 'react-mailchimp-form'

const Footer = () => (
  <footer className="sf" id="footer">
    <div className="container">
      {/* <div className="row sf__subscribe"> */}
      {/*   <div className="col-sm-12"> */}
      {/*     <h3>Insights &amp; advice delivered weekly to your inbox</h3> */}
      {/*     <div id="mc_embed_signup"> */}
      {/*       <Subscribe /> */}
      {/*       {/1* <form className="sf__subscribe-form form-inline validate" action="http://salestribe.us15.list-manage.com/subscribe/post?u=7261abe85d7e9ca8825064a2a&amp;id=fdf97f4415" method="post" id="mc-embedded-subscribe-form" target="_blank" name="mc-embedded-subscribe-form" noValidate> */}
              
      {/*         <label htmlFor="email">Subscribe</label> */}
      {/*         <input type="email" className="form-control boxed" placeholder="Your email address" id="mce-EMAIL" name="EMAIL" /> */}
      {/*           <div style={{position: "absolute", left:"-5000px"}} aria-hidden="true"> */}
      {/*             <button type="submit" name="button"></button> */}
      {/*             <input type="text" name="b_7261abe85d7e9ca8825064a2a_fdf97f4415" tabIndex="-1" /> */}
      {/*           </div> */}
      {/*           <div id="mce-responses" className="clear" style={{marginTop: "0.5em"}}> */}
      {/*             <div className="response" id="mce-error-response" style={{display: "none"}}></div> */}
      {/*             <div className="response" id="mce-success-response" style={{display: "none"}}></div> */}
      {/*         </div> */}  
      {/*       </form> *1/} */}
      {/*     </div> */}
      {/*   </div> */}
      {/* </div> */}
      <div className="row sf__links">
        <div className="col-xs-6 col-sm-3">
          <ul className="sf__nav">
            <li><Link to="/about-salestribe/">About SalesTribe</Link></li>
            <li><Link to="/knowledge-centre/press/">Recent press</Link></li> 
            <li><Link to="/contact-us/">Contact us</Link></li>
            <li><Link to="/privacy-policy/">Privacy Policy</Link></li>
          </ul>
        </div>
        <div className="col-xs-6 col-sm-3">
          <ul className="sf__nav">
            {/* <li>&copy; SalesTribe</li> */}
          </ul>
        </div>
        <div className="col-xs-12 col-sm-offset-3 col-sm-3">
          <nav className="social-nav sf__social-nav">
            <h4>Follow SalesTribe</h4>
            <ul>
              <li className="linkedin">
                <a href="https://www.linkedin.com/company/12905201" rel="noopener noreferrer" target="_blank">
                  <span className="sr-only">LinkedIn</span>
                   <span className="icon icon-linkedin"></span>
                </a>
              </li>
              <li className="facebook">
                <a href="https://www.facebook.com/salestribeint/" rel="noopener noreferrer" target="_blank">
                  <span className="sr-only">Facebook</span>
                  <span className="icon icon-facebook-f"></span>
                </a>
              </li>
              <li className="twitter">
                <a href="https://twitter.com/SalesTribeInt" rel="noopener noreferrer" target="_blank">
                  <span className="sr-only">Twitter</span>
                  <span className="icon icon-twitter"></span>
                </a>
              </li>
              {/* <li className="googleplus"> */}
              {/*   <a href="https://plus.google.com/b/106688056473316642730/?hl=en" rel="noopener noreferrer" target="_blank"> */}
              {/*     <span className="sr-only">Google+</span> */}
              {/*     <span className="icon icon-google-plus"></span> */}
              {/*   </a> */}
              {/* </li> */}
              <li className="youtube">
                <a href="https://www.youtube.com/channel/UCToxQnBsvBmQOhPrG_gAJ-Q" rel="noopener noreferrer" target="_blank">
                  <span className="sr-only">YouTube</span>
                  <span className="icon icon-youtube"></span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </footer>
)

export const FooterAlternate1 = () => (
  <div className="block block--green banner">
      <div className="container">
          <div className="row">
              <div className="col-sm-12 col-md-offset-1 col-md-10">
                  <h2 className="banner__title">Career advice, event exclusives and mentoring for the sales professional.</h2>
                  <div className="banner__buttons">
                      <a href="https://community.salestribe.com/" className="btn btn-default" target="_self">Join for Free</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
)

export const FooterAlternate2 = () => (
  <div className="block block--green banner">
      <div className="container">
          <div className="row">
              <div className="col-sm-12 col-md-offset-1 col-md-10">
                  <h2 className="banner__title">SalesTribe helps you to re-purpose, re-invent and re-connect into exciting new career opportunities.</h2>
                  <div className="banner__buttons">
                      <a href="https://community.salestribe.com/" className="btn btn-default" target="_self">Join for Free</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
)

export const FooterAlternate3 = () => (
  <div className="block block--grey buttons">
      <div className="container">
        <div className="buttons__items row">
          <div className="buttons__item col">
              <h3 className="buttons__title"><strong>Ready to make a change?</strong></h3>
              <button className="btn btn-default" data-toggle="modal" data-target="#signupModal">Register for Free!</button>
          </div>
          <div className="buttons__item col">
            <h3 className="buttons__title"><strong>Not sure where to start?</strong></h3>
            <Link to="/enquiries/" className="btn btn-default">Contact an advisor</Link>
          </div>
        </div>
      </div>
  </div>
)

const url = "https://salestribe.us15.list-manage.com/subscribe/post?u=7261abe85d7e9ca8825064a2a&amp;id=fdf97f4415";
class Subscribe extends Component {
  render() {
    return (
      <Mailchimp
      action={url} 
      
      //Adding multiple fields:
      fields={[
        {
          name: 'EMAIL',
          placeholder: 'Your email address',
          type: 'email',
          required: true,
        }
      ]}
      // Change predetermined language
      messages = {
        {
          sending: "Subscribing...",
          success: "Thank you for subscribing!",
          error: "An unexpected internal error has occurred.",
          empty: "You must write an e-mail.",
          duplicate: "Too many subscribe attempts for this email address",
          button: "Subscribe"
        }
      }
      // Add a personalized class
      className='sf__subscribe-form form-inline'
      />
    )
  }
}

export default Footer
