import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"

import "isomorphic-fetch"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      geoLocation: null,
    }
  }

  componentDidMount() {
    window.north.init()
    if (!sessionStorage.getItem("geoLocation")) {
      fetch(
        "https://07hz0eqgq5.execute-api.us-east-1.amazonaws.com/prod/location"
      )
        .then((response) => response.json())
        .then((response) => {
          sessionStorage.setItem("geoLocation", response.country)
          this.setState({
            geoLocation: response.country,
          })
        })
    } else {
      this.setState({
        geoLocation: sessionStorage.getItem("geoLocation"),
      })
    }
  }

  render() {
    const { location } = this.props
    const { geoLocation } = this.state

    return (
      <>
        <header className="sh" id="header menu" role="banner">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="sh__brand">
                  <Link
                    className="sh__brandlink"
                    to="/"
                    title="SalesTribe"
                    rel="home"
                  >
                    <img
                      src="/static/images/svg/salestribe-brand.svg"
                      width="180"
                      height="34"
                      alt="SalesTribe"
                    />
                  </Link>
                </h1>

                <a
                  style={{ position: "absolute", right: 60 }}
                  target="_self"
                  href="https://community.salestribe.com/sign_in"
                >
                  <span
                    id="mobile-login"
                    className="btn btn-primary login-btn hidden-lg hidden-sm hidden-xs"
                  >
                    Login
                  </span>
                </a>
                <button
                  className="sh__hamburger"
                  onClick={() => {
                    document.querySelector("html").classList.toggle("offcanvas")
                  }}
                  aria-expanded="false"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="group">
                    <span className="element"></span>
                    <span className="element"></span>
                    <span className="element"></span>
                  </span>
                </button>
                <nav
                  className="sh__nav"
                  role="navigation"
                  aria-label="SalesTribe navigation"
                >
                  <ul>
                    <li
                      className={`has-subpages ${
                        location
                          ? location.pathname.includes("how-it-works")
                            ? "active"
                            : ""
                          : ""
                      }`}
                    >
                      <Link to="/digital-first-sales/">Digital First Sales Training</Link>
                      <div className="sh__subnav">
                        <div className="sh__caption">
                          <h3>
                            <strong>Digital First Sales Training</strong>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li
                      className={`has-subpages ${
                        location
                          ? location.pathname.includes("how-it-works")
                            ? "active"
                            : ""
                          : ""
                      }`}
                    >
                      <Link to="/outsource-sales-marketing/">Outsourced Sales & Marketing</Link>
                      <div className="sh__subnav">
                        <div className="sh__caption">
                          <h3>
                            <strong>Outsourced Sales & Marketing</strong>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li
                      className={`has-subpages ${
                        location
                          ? location.pathname.includes("knowledge-centre")
                            ? "active"
                            : ""
                          : ""
                      }`}
                    >
                      <Link to="/knowledge-centre/">Knowledge centre</Link>
                      <div className="sh__subnav">
                        <div className="sh__caption">
                          <h3>
                            <strong>Knowledge centre</strong>
                          </h3>
                          <p>
                            Search for the latest articles and video&#39;s on
                            sales best practices.
                          </p>
                        </div>
                        <ul className="sh__subnav-list">
                          <li className="sh__subnav-item">
                            <Link to="/knowledge-centre/challenges/">
                              Challenges
                            </Link>
                          </li>
                          <li className="sh__subnav-item">
                            <Link to="/knowledge-centre/insights/">
                              Insights
                            </Link>
                          </li>
                          {/* <li className="sh__subnav-item"> */}
                          {/*   <Link to="/knowledge-centre/library/"> */}
                          {/*     The library */}
                          {/*   </Link> */}
                          {/* </li> */}
                        </ul>
                      </div>
                    </li>
                    {/* <li */}
                    {/*   className={`has-subpages ${ */}
                    {/*     location */}
                    {/*       ? location.pathname.includes("solutions") */}
                    {/*         ? "active" */}
                    {/*         : "" */}
                    {/*       : "" */}
                    {/*   }`} */}
                    {/* > */}
                    {/*   <Link to="/solutions/">Solutions</Link> */}

                    {/*   <div className="sh__subnav"> */}
                    {/*     <div className="sh__caption"> */}
                    {/*       <h3> */}
                    {/*         <strong>Solutions</strong> */}
                    {/*       </h3> */}
                    {/*       <p> */}
                    {/*         SalesTribe offers a range of services to sales */}
                    {/*         people, including getting connected into businesses. */}
                    {/*       </p> */}
                    {/*     </div> */}
                    {/*     <ul className="sh__subnav-list"> */}
                    {/*       <li className="sh__subnav-item"> */}
                    {/*         <Link to="/solutions/im-sales-professional/"> */}
                    {/*           Sales People */}
                    {/*         </Link> */}
                    {/*       </li> */}
                    {/*       <li className="sh__subnav-item"> */}
                    {/*         <Link to="/solutions/im-start-business/"> */}
                    {/*           Companies */}
                    {/*         </Link> */}
                    {/*       </li> */}
                    {/*     </ul> */}
                    {/*   </div> */}
                    {/* </li> */}
                    {/* {geoLocation === "AU" && (
                      <li
                        className={`${
                          location
                            ? location.pathname.includes("sales-jobs")
                              ? "active"
                              : ""
                            : ""
                        }`}
                      >
                        <Link to="/sales-jobs/">Featured Jobs</Link>
                      </li>
                    )} */}
                   
                    {/* <li>
                      <Link to="/press/">Recent Press</Link>
                    </li> */}
                    {/* <li
                      className={`${
                        location
                          ? location.pathname.includes("events")
                            ? "active"
                            : ""
                          : ""
                      }`}
                    >
                      <Link to="/events/">Events</Link>
                    </li> */}
                  </ul>
                </nav>
                <nav
                  className="sh__links"
                  role="group"
                  aria-label="SalesTribe registration links"
                >
                  <a target="_self" href="https://community.salestribe.com/">
                    <button
                      style={{ marginRight: 10 }}
                      className="btn-default btn"
                    >
                      Join Community
                    </button>
                  </a>
                  <a target="_self" href="https://community.salestribe.com/sign_in">
                    <span
                      style={{ paddingLeft: 30, paddingRight: 30 }}
                      className="btn"
                    >
                      Login
                    </span>
                  </a>
                  {/* <button className="btn btn-default" data-toggle="modal" data-target="#signupModal">Register for Free!</button> */}
                </nav>
              </div>
            </div>
          </div>
        </header>

        <nav className="sn" id="menu" style={{ width: "100%", height: "100%" }}>
          <div className="sn__header">
            <Link
              className="sn__brand"
              to="/"
              title="SalesTribe"
              rel="home"
              role="banner"
            >
              <img
                src="/static/images/svg/salestribe-brand.svg"
                width="180"
                height="34"
                alt="SalesTribe"
              />
            </Link>

            <div className="sn__close">
              <button data-action="close">
                <span className="sr-only">Close Menu</span>
                <span className="icon"></span>
              </button>
            </div>
          </div>
          <div className="sn__menu">
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <ul className="sn__list">
                    <li className="has-children ">
                      <Link to="/how-it-works/">How it works</Link>

                      <button type="button" className="toggle">
                        <span className="sr-only">Open sub-navigation</span>
                      </button>
                      <div className="sn__subnav">
                        <ul>
                          <li className="sn__subitem">
                            <Link to="/how-it-works/sales-professionals/">
                              Sales People
                            </Link>
                          </li>

                          <li className="sn__subitem">
                            <Link to="/how-it-works/start-businesses/">
                              Companies
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    {/* <li className="has-children active"> */}
                    {/*   <Link to="/solutions/">Solutions</Link> */}

                    {/*   <button type="button" className="toggle"> */}
                    {/*     <span className="sr-only">Open sub-navigation</span> */}
                    {/*   </button> */}
                    {/*   <div className="sn__subnav"> */}
                    {/*     <ul> */}
                    {/*       <li className="sn__subitem"> */}
                    {/*         <Link to="/solutions/im-sales-professional/"> */}
                    {/*           Sales People */}
                    {/*         </Link> */}
                    {/*       </li> */}

                    {/*       <li className="sn__subitem"> */}
                    {/*         <Link to="/solutions/im-start-business/"> */}
                    {/*           Companies */}
                    {/*         </Link> */}
                    {/*       </li> */}
                    {/*     </ul> */}
                    {/*   </div> */}
                    {/* </li> */}
                    <li className="has-children ">
                      <Link to="/knowledge-centre/">Knowledge centre</Link>

                      <button type="button" className="toggle">
                        <span className="sr-only">Open sub-navigation</span>
                      </button>
                      <div className="sn__subnav">
                        <ul>
                          <li className="sn__subitem">
                            <Link to="/knowledge-centre/challenges/">
                              Challenges
                            </Link>
                          </li>

                          <li className="sn__subitem">
                            <Link to="/knowledge-centre/insights/">
                              Insights
                            </Link>
                          </li>

                          {/* <li className="sn__subitem"> */}
                          {/*   <Link to="/knowledge-centre/library/"> */}
                          {/*     The library */}
                          {/*   </Link> */}
                          {/* </li> */}
                        </ul>
                      </div>
                    </li>
                    {/* {geoLocation === "AU" && (
                      <li className="">
                        <Link to="/sales-jobs/">Featured Jobs</Link>
                      </li>
                    )} */}
                    {/* <li>
                      <Link to="/press/">Recent Press</Link>
                    </li> */}
                    {/* <li className="">
                      <Link to="/events/">Events</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sn__footer">
            <nav
              className="sn__links"
              role="group"
              aria-label="SalesTribe registration links"
            >
              <a target="_self" href="https://community.salestribe.com/">
                <button className="btn btn-default">Join Community</button>
              </a>
              <a target="_self" href="https://community.salestribe.com/sign_in">
                <span className="btn btn-primary login-btn hidden-lg hidden-md">
                  Login
                </span>
              </a>
              {/* <button className="btn btn-default" data-toggle="modal" data-target="#loginModal">Register for Free!</button> */}
            </nav>
          </div>
        </nav>
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  location: PropTypes.object,
}

Header.defaultProps = {
  siteTitle: ``,
  location: { pathname: "/" },
}

// const Header = ({siteTitle, location}) => (
//   <>
//   <header className="sh" id="header" role="banner">
//     <div className="container">
//       <div className="row">
//         <div className="col-sm-12">
//           <h1 className="sh__brand">
//             <Link className="sh__brandlink" to="/" title="SalesTribe" rel="home">
//               <img src="/static/images/svg/salestribe-brand.svg" width="180" height="34" alt="SalesTribe" />
//             </Link>
//           </h1>
//           <button className="sh__hamburger" onClick={() => {
//             document.querySelector('html').classList.toggle('offcanvas')
//           }} aria-expanded="false">
//             <span className="sr-only">Toggle navigation</span>
//             <span className="group">
//               <span className="element"></span>
//               <span className="element"></span>
//               <span className="element"></span>
//             </span>
//           </button>
//           <nav className="sh__nav" role="navigation" aria-label="SalesTribe navigation">
//             <ul>
//             <li className={`has-subpages ${location ? location.pathname.includes('how-it-works') ? 'active' : '' : '' }`}>
//                 <Link to="/how-it-works/">How it works</Link>
//                 <div className="sh__subnav">
//                   <div className="sh__caption">
//                     <h3><strong>How it works</strong></h3>
//                     <p>SalesTribe connects sales people into businesses.</p>
//                   </div>
//                   <ul className="sh__subnav-list">
//                     <li className="sh__subnav-item"><Link to="/how-it-works/sales-professionals/">Sales People</Link></li>
//                     <li className="sh__subnav-item"><Link to="/how-it-works/start-businesses/">Companies</Link></li>
//                   </ul>
//                 </div>
//               </li><li className={`has-subpages ${location ? location.pathname.includes('solutions') ? 'active' : '' : '' }`}>
//               <Link to="/solutions/">Solutions</Link>

//               <div className="sh__subnav">
//                 <div className="sh__caption">
//                 <h3><strong>Solutions</strong></h3>
//                 <p>SalesTribe offers a range of services to sales people, including getting connected into businesses.</p>
//                 </div>
//                 <ul className="sh__subnav-list">
//                   <li className="sh__subnav-item"><Link to="/solutions/im-sales-professional/">Sales People</Link></li>
//                   <li className="sh__subnav-item"><Link to="/solutions/im-start-business/">Companies</Link></li>
//                 </ul>
//               </div>
//               </li>

//               {geoLocation === "AU" &&
//                 <li className={`${location ? location.pathname.includes('sales-jobs') ? 'active' : '' : '' }`}>
//                   <Link to="/sales-jobs/">Sales Jobs</Link>
//                 </li>
//               }

//               <li className={`has-subpages ${location ? location.pathname.includes('knowledge-centre') ? 'active' : '' : '' }`}>
//                 <Link to="/knowledge-centre/">Knowledge centre</Link>
//                 <div className="sh__subnav">
//                   <div className="sh__caption">
//                     <h3><strong>Knowledge centre</strong></h3>
//                     <p>Search for the latest articles and video&#39;s on sales best practices.</p>
//                   </div>
//                   <ul className="sh__subnav-list">
//                     <li className="sh__subnav-item"><Link to="/knowledge-centre/challenges/">Challenges</Link></li>
//                     <li className="sh__subnav-item"><Link to="/knowledge-centre/insights/">Insights</Link></li>
//                     <li className="sh__subnav-item"><Link to="/knowledge-centre/library/">The library</Link></li>
//                     <li className="sh__subnav-item"><Link to="/knowledge-centre/press/">Recent press</Link></li>
//                   </ul>
//                 </div>
//               </li>
//               <li className={`${location ? location.pathname.includes('testimonials') ? 'active' : '' : '' }`}>
//                 <Link to="/testimonials/">Testimonials</Link>
//               </li>
//               <li className={`${location ? location.pathname.includes('events') ? 'active' : '' : '' }`}>
//                 <Link to="/events/">Events</Link>
//               </li>
//             </ul>
//           </nav>
//           <nav className="sh__links" role="group" aria-label="SalesTribe registration links">
//             {/* <Login /> */}
//             <button className="btn btn-default" data-toggle="modal" data-target="#signupModal">Register for Free!</button>
//           </nav>
//         </div>
//       </div>
//     </div>
//   </header>

// <nav className="sn" id="menu">
// <div className="sn__header">
//     <Link className="sn__brand" to="/" title="SalesTribe" rel="home" role="banner">
//         <img src="/static/images/svg/salestribe-brand.svg" width="180" height="34" alt="SalesTribe" />
//     </Link>
//     <div className="sn__close">
//         <button data-action="close">
//             <span className="sr-only">Close Menu</span>
//             <span className="icon"></span>
//         </button>
//     </div>
// </div>
// <div className="sn__menu">
//     <div className="container">
//         <div className="row">
//             <div className="col-xs-12">
//                 <ul className="sn__list">
//                     <li className="has-children ">
//                         <Link to="/how-it-works/">How it works</Link>

//                         <button type="button" className="toggle">
//                             <span className="sr-only">Open sub-navigation</span>
//                         </button>
//                         <div className="sn__subnav">
//                             <ul>
//                                 <li className="sn__subitem"><Link to="/how-it-works/sales-professionals/">Sales People</Link></li>

//                                 <li className="sn__subitem"><Link to="/how-it-works/start-businesses/">Companies</Link></li>
//                             </ul>
//                         </div>
//                     </li><li className="has-children active">
//                         <Link to="/solutions/">Solutions</Link>

//                         <button type="button" className="toggle">
//                             <span className="sr-only">Open sub-navigation</span>
//                         </button>
//                         <div className="sn__subnav">
//                             <ul>
//                                 <li className="sn__subitem"><Link to="/solutions/im-sales-professional/">Sales People</Link></li>

//                                 <li className="sn__subitem"><Link to="/solutions/im-start-business/">Companies</Link></li>
//                             </ul>
//                         </div>
//                     </li><li className="has-children ">
//                         <Link to="/knowledge-centre/">Knowledge centre</Link>

//                         <button type="button" className="toggle">
//                             <span className="sr-only">Open sub-navigation</span>
//                         </button>
//                         <div className="sn__subnav">
//                             <ul>
//                                 <li className="sn__subitem"><Link to="/knowledge-centre/challenges/">Challenges</Link></li>

//                                 <li className="sn__subitem"><Link to="/knowledge-centre/insights/">Insights</Link></li>

//                                 <li className="sn__subitem"><Link to="/knowledge-centre/library/">The library</Link></li>

//                                 <li className="sn__subitem"><Link to="/knowledge-centre/press/">Recent press</Link></li>
//                             </ul>
//                         </div>
//                     </li>
//                     <li className="">
//                         <Link to="/testimonials/">Testimonials</Link>

//                     </li>
//                     <li className="">
//                         <Link to="/events/">Events</Link>

//                     </li>

//                 </ul>
//             </div>
//         </div>
//     </div>
// </div>
// <div className="sn__footer">
//     <nav className="sn__links" role="group" aria-label="SalesTribe registration links">
//         {/* <button className="btn btn-default" data-toggle="modal" data-target="#loginModal">Login</button> */}
//         <button className="btn btn-default" data-toggle="modal" data-target="#signupModal">Register for Free!</button>
//     </nav>
// </div>
// </nav>
// </>

// )

export default Header
