import React, { Component } from "react"

class Modals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonVisibility: 'visible',
            responseMessage: '',
            signUp: {
                jobRole: 'salesperson',
                firstName: '',
                lastName: '',
                linkedIn: 'None',
                jobTItle: '',
                email: ''
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        var state = Object.assign(this.state, {
            signUp: Object.assign(this.state.signUp, { [event.target.id]: event.target.value }),
        });
        
        this.setState(state);
    }

    handleSubmit(event) {
        this.setState({buttonVisibility: 'invisible'});
        console.log('State Data: ' + JSON.stringify(this.state));
        event.preventDefault();

        var apigClient = window.apigClientFactory.newClient();
        var body = {
            email: this.state.signUp.email,
            firstName: this.state.signUp.firstName,
            lastName: this.state.signUp.lastName,
            linkedIn: this.state.signUp.linkedIn,
            jobTitle: this.state.signUp.jobTitle,
            jobRole: this.state.signUp.jobRole
        };
        
        apigClient.userPost({}, body, {})
            .then(function(result){
                return result;
            })
            .then(result => {
                if (result.data !== "Complete") {
                    this.setState({
                        responseMessage: 'An error occurred while adding your details'
                    })
                } else {
                    this.setState({
                        responseMessage: 'Thank you for registering!'
                    })
                }
                console.log(result);
            })
            .catch( function(result){
                console.log(result);
            });
    }

    render() {
        return (
            <>
                <div className="modal fade" id="signupModal" tabIndex="-1" role="dialog" aria-labelledby="signupModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span className="sr-only">Close</span>
                                </button>
                                <div className="row">
                                    <div className="col-xs-offset-1 col-xs-10 col-md-offset-2 col-md-8">
                                        <h2 className="modal-title" id="signupModalLabel">Register for Free!</h2>
                                    </div>
                                </div>
                                <div className="row" id="member-choice">
                                    <div className="col-xs-offset-1 col-xs-10 col-md-offset-2 col-md-8">
                                        {/* <div id="member-chooser" className="form-group has-error">
                                            <label htmlFor="jobRole">Please choose</label>
                                            <select className="form-control" id="jobRole" style={{width:"100%"}} onChange={this.handleChange}>
                                                <option value="salesperson">I'm a salesperson</option>
                                                <option value="startup">I'm a small business</option>
                                                <option value="general">I'm neither</option>
                                            </select>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body signup-form" id="signup-form-body">
                                <div className="row">
                                    <div className="col-xs-offset-1 col-xs-10 col-md-offset-2 col-md-8">
                                        <form id="join-form" autoComplete="off" onSubmit={this.handleSubmit}>
                                            <div className="form-group" id="signup-form-additional">
                                                <label htmlFor="signup_more_information">Tell us your job title...</label>
                                                <input type="text" className="form-control" name="jobTitle" id="jobTitle" placeholder="Tell us your role..." required="required" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="signup_firstname">First name</label>
                                                <input type="text" className="form-control" name="firstName" id="firstName" placeholder="First name" required="required" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="signup_lastname">Last name</label>
                                                <input type="text" className="form-control" name="lastName" id="lastName" placeholder="Last name" required="required" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="signup_email">Email</label>
                                                <input type="email" className="form-control" name="email" id="email" placeholder="Email" required="required" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="signup_linkedin">LinkedIn URL</label>
                                                <input type="text" className="form-control" name="linkedIn" id="linkedIn" placeholder="LinkedIn URL" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className={`btn btn-default btn-block ${this.state.buttonVisibility}`}>Submit</button>
                                                <h3>{this.state.responseMessage}</h3>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Modals;